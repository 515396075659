/*eslint-disable*/
import axios from 'axios';
import {baseApiUrl} from "../../../config.js";
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import PopupTemplate from '@arcgis/core/PopupTemplate';

const state = {
  mapWebScene: null,
  mapView: null,
  initialDiscussions: [],
  discussionComments: [],
  currentDiscussionId: null,
  currentDiscussion: null,
  currentComments: [],
  userName: null,
  user: null,
  commentAttachments: [],
  staticDiscussionLayerId: "discussion-graphics",
  staticDiscussionLayerTitle: "User Discussions",
  mapWebSceneLoadFlag: null,
  isAddNewDiscussion: false,
  userList: []
}

function initialState() {
  return {
    mapWebScene: null,
    mapView: null,
    initialDiscussions: [],
    discussionComments: [],
    currentDiscussionId: null,
    currentDiscussion: null,
    currentComments: [],
    userName: null,
    user: null,
    commentAttachments: [],
    staticDiscussionLayerId: "discussion-graphics",
    staticDiscussionLayerTitle: "User Discussions",
    mapWebSceneLoadFlag: null,
    isAddNewDiscussion: false,
    userList: []
  }
}
const mutations = {
  saveMapWebScene(state, data) {
    state.mapWebScene = data
  },
  saveMapView(state, data) {
    state.mapView = data
  },
  saveUserName(state, data) {
    state.userName = data
  },
  saveUser(state, data) {
    state.user = data
  },
  insertUserList(state, data){
    state.userList = data
  },
  insertInitialDiscussions(state, payload) {
    state.initialDiscussions.push(payload)
  },
  resetInitialDiscussions(state) {
    state.initialDiscussions = []
  },
  resetDiscussionComments(state) {
    state.discussionComments = []
  },
  insertDiscussionComments(state, payload) {
    state.discussionComments.push(payload)
  },
  setCurrentDiscussionId(state, payload) {
    state.currentDiscussionId = payload
  },
  setCurrentDiscussion(state, payload) {
    state.currentDiscussion = payload
  },
  setCurrentComments(state, payload) {
    payload.comments.forEach(item=>{
      state.currentComments.push(item)
    })
  },
  clearCurrentComments(state) {
    state.currentComments = []
  },
  addNewComment(state, payload) {
    var objectId = payload.objectId
    var currentComments = state.discussionComments.filter(obj=>obj.objectId == objectId)
    if (currentComments.length) {
      currentComments[0].comments.push(payload.comment)
    }
    else {
      var discussionComment = {
        decision: payload.decision,
        approvalStateCode: payload.approvalStateCode,
        objectId: objectId,
        comments: [payload.comment]
      }
      state.discussionComments.push(discussionComment)
    }
  },
  finalizeDiscussion(state, payload) {
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload.objectId)
    currentDiscussion[0].approvalStatus = payload.decision?"Approved":currentDiscussion.approvalStatus
  },
  clearAttachments(state) {
    state.commentAttachments = []
  },
  addAttachment(state, payload) {
    state.commentAttachments.push(payload)
  },
  removeAttachment(state, index) {
    state.commentAttachments.splice(index,1)
  },
  setMapWebSceneLoadFlag(state, payload) {
    state.mapWebSceneLoadFlag = payload
  },
  setIfAddNewDiscussionClicked(state, payload){
    state.isAddNewDiscussion = payload
  },
}

const actions = {
  async fetchDiscussionComments ({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject) {
      let url = baseApiUrl + "fetchComments"
      let data    = {
        discussionIds: payload.objectIds
      }
      let config  = {
        responseType: 'json'
      }
      axios.post(url, data, config).then((result)=>{
        commit("resetDiscussionComments")
        result.data.forEach((item,index) => {
          commit('insertDiscussionComments', item);
        })
        resolve();
      }).catch(error=>{
        reject(error);
      })
    })
  },
  setCurrentDiscussionDetails({state, commit}, payload) {
    commit("setCurrentDiscussionId", payload)
    var currentDiscussion = state.initialDiscussions.filter(obj=>obj.objectId == payload)
    if (currentDiscussion.length) {
      commit("setCurrentDiscussion", currentDiscussion)
    }
    var currentComments = state.discussionComments.filter(obj=>obj.objectId == payload)
    commit("clearCurrentComments")
    if (currentComments.length) {
      commit("setCurrentComments", currentComments[0])
    }
  },
  fetchDiscussionLayer({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject) {
      var url = baseApiUrl + "fetchDiscussions"
      var data = {filterLayer: payload}
      var config = {
        responseType: 'json'
      }
      axios.post(url, data, config).then(response=>{
        var discussionsList = response.data.features
        var discussionsCount = 0;
          const discussionGraphicsLayer = new GraphicsLayer({
            id: state.staticDiscussionLayerId,
            title: state.staticDiscussionLayerTitle
          });
          const popupTemplate = new PopupTemplate({
            title: "Details",
            content: [{
              type: "fields",
              fieldInfos: [
                {
                  fieldName: "id",
                  label: "Object ID"
                },
                {
                  fieldName: "discussionCreatedBy",
                  label: "Created by"
                },
                {
                  fieldName: "disciplineList",
                  label: "Discipline"
                },
                {
                  fieldName: "workPackage",
                  label: "Work Package"
                },
                {
                  fieldName: "message",
                  label: "Message"
                },
                {
                  fieldName: "date",
                  label: "Date"
                },
                {
                  fieldName: "approvalState",
                  label: "Approval Status"
                },
              ]
            }]
          });
          discussionsList.forEach(discussion=>{
            var discussionEntry = new Graphic({
              geometry: {
                type: "point",
                latitude: discussion.geometry.latitude,
                longitude:discussion.geometry.longitude,
                z: discussion.geometry.height
              },
              attributes: discussion.attributes,
              symbol: {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                url: "DefaultMarker.png",
                width: 20
              },
              popupTemplate: popupTemplate
            });

            discussionGraphicsLayer.graphics.push(discussionEntry)

          })
          resolve(discussionGraphicsLayer)
      })
    });
  }
}

const getters = {}



export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
