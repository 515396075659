/* eslint-disable */
var baseApiUrl = ''
var appBaseUrl = ''
if (process.env.NODE_ENV === 'production') {
  // baseApiUrl = 'https://discussion-tool-demo-api.geodemosolutions.com/public/'
  // appBaseUrl = 'https://discussion-tool-demo.geodemosolutions.com/#/'
  baseApiUrl = 'https://discussion-tool-demo-api.geodemosolutions.com/public/'
  appBaseUrl = 'https://gwhu-discussion-tool.geodemosolutions.com/#/'
 }
else {
  baseApiUrl = 'http://localhost/discussion-tool-api/public/'
  appBaseUrl = 'https://gwhu-discussion-tool.geodemosolutions.com/#/'
}

export {
  baseApiUrl,
  appBaseUrl
}
